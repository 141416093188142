<template>
  <div>
    <NavigationBar
      titel="Abfrage erstellen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <request-editor v-model="request"></request-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase";
import { AVAILABILITY } from "@/store/modules.js";
import { CREATE_REQUEST } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import RequestEditor from "@/components/availability/requests/RequestEditor.vue";

export default {
  name: "availability-request-new",
  components: {
    NavigationBar,
    RequestEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],
      helpers: {
        formIsValid: false,
      },

      request: {
        title: null,
        description: null,
        type: {
          id: null,
          title: null,
          shortTitle: null,
          color: null,
          description: null,
        },
        location: {
          name: null,
        },
        timeSlots: {
          start: null,
          end: null,
          data: [],
        },
        config: {
          organizer: {
            isAppointed: false,
            hasAdditionalPermissions: false,
            description: "",
            personIds: [],
            personData: [],
          },
          attendance: {
            isMandatory: false,
            isLimited: false,
            description: null,
            allowedAttendeesIds: ["all"],
            allowedPersons: [],
            allowedUserGroups: [
              {
                title: "Alle",
                id: "all",
              },
            ],
          },
          responses: {
            areBinding: false,
            areLocked: false,
            deadline: {
              isCustom: false,
              date: null,
              time: null,
              timestamp: null,
            },
          },
        },
      },
    };
  },
  computed: {
    timeSlots() {
      const convertedData = this.request.timeSlots.data.map((item) => {
        return {
          ...item,
          start: {
            timestamp: this.convertToTimestamp(
              item.start.date,
              item.start.time
            ),
          },
          end: {
            timestamp: this.convertToTimestamp(item.end.date, item.end.time),
          },
        };
      });
      return convertedData.sort(
        (a, b) => a.start.timestamp - b.start.timestamp
      );
    },
    first() {
      return this.timeSlots[0].start.timestamp;
    },
    last() {
      return this.timeSlots[this.timeSlots.length - 1].end.timestamp;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {},
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    checkAttendeesData() {
      const hasItems = (array) => array.length > 0;
      const filterOutAllString = (array) =>
        array.filter((item) => item !== "all");
      const filterOutAllObject = (array) =>
        array.filter((item) => item.id != "all");

      const attendance = this.request.config.attendance;
      const allowedPersonsIds = attendance.allowedPersons.map(
        (item) => item.uid
      );
      const allowedUserGroupsIds = attendance.allowedUserGroups
        .map((item) => item.id)
        .filter((item) => item != "all");

      const attendanceIsLimited = attendance.isLimited;
      const noAttendanceDescription = attendance.description ? false : true;
      const noAttendanceLimitationsSelected =
        allowedPersonsIds.length == 0 && allowedUserGroupsIds.length == 0;

      // if no persons or user groups are selected, make sure all is included as default
      if (noAttendanceLimitationsSelected) {
        attendance.allowedAttendeesIds = filterOutAllString(
          attendance.allowedAttendeesIds
        );
        attendance.allowedUserGroups = [{ title: "Alle", id: "all" }];
      }
      // 1: else if user groups but no persons are selected, make sure all is not included
      // 2: else if persons but no user groups are selected, make sure all is not included
      // 3: else if persons and user groups are selected, make sure all is not included
      else if (
        (allowedPersonsIds.length == 0 && hasItems(allowedUserGroupsIds)) ||
        (hasItems(allowedPersonsIds) && allowedUserGroupsIds.length == 0) ||
        (hasItems(allowedPersonsIds) && hasItems(allowedUserGroupsIds))
      ) {
        attendance.allowedAttendeesIds = filterOutAllString(
          attendance.allowedAttendeesIds
        );
        attendance.allowedUserGroups = filterOutAllObject(
          attendance.allowedUserGroups
        );
      } else {
        console.log("error in attendance data");
      }

      if (!attendanceIsLimited) {
        attendance.allowedUserGroups = [{ title: "Alle", id: "all" }];
        attendance.allowedPersons = [];
      }

      if (
        attendanceIsLimited &&
        noAttendanceDescription &&
        noAttendanceLimitationsSelected
      ) {
        attendance.isLimited = false;
      }

      const personAttendanceIds = attendance.allowedPersons.map(
        (item) => item.uid
      );
      const userGroupAttendanceIds = attendance.allowedUserGroups.map(
        (item) => item.id
      );

      attendance.allowedAttendeesIds = personAttendanceIds.concat(
        userGroupAttendanceIds
      );
    },
    createItem() {
      this.checkAttendeesData();
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        // Sort timeSlots
        this.request.timeSlots.data = this.request.timeSlots.data.sort(
          function (a, b) {
            console.log(new Date(a.start.date), new Date(b.start.date));
            return new Date(a.start.date) - new Date(b.start.date);
          }
        );

        this.$store.dispatch(`${AVAILABILITY}${CREATE_REQUEST}`, {
          organizationId: this.$route.params.organizationId,

          title: this.request.title,
          description: this.request.description,
          type: {
            id: this.request.type.id,
            title: this.request.type.title,
            shortTitle: this.request.type.shortTitle,
            description: this.request.type.description,
            color: this.request.type.color,
          },
          location: {
            name: this.request.location.name,
          },

          timeSlots: {
            start: {
              timestamp: this.first,
            },
            end: {
              timestamp: this.last,
            },
            data: this.timeSlots,
          },

          config: {
            organizer: {
              isAppointed: this.request.config.organizer.isAppointed,
              hasAdditionalPermissions:
                this.request.config.organizer.hasAdditionalPermissions,
              description: this.request.config.organizer.description,
              personIds: this.request.config.organizer.personIds,
              personData: this.request.config.organizer.personData,
            },
            attendance: {
              isMandatory: this.request.config.attendance.isMandatory,
              isLimited: this.request.config.attendance.isLimited,
              description: this.request.config.attendance.description,
              allowedAttendeesIds:
                this.request.config.attendance.allowedAttendeesIds,
              allowedPersons: this.request.config.attendance.allowedPersons,
              allowedUserGroups:
                this.request.config.attendance.allowedUserGroups,
            },
            responses: {
              areBinding: this.request.config.responses.areBinding,
              areLocked: this.request.config.responses.areLocked,
              deadline: {
                isCustom: this.request.config.responses.deadline.isCustom,
                timestamp: this.convertToTimestamp(
                  this.request.config.responses.deadline.date,
                  this.request.config.responses.deadline.time
                ),
              },
            },
          },
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
