<template>
  <div>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
          <div class="mt-7">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="7">
                      <v-text-field
                        v-model.trim="requestLocal.title"
                        :counter="35"
                        label="Titel"
                        required
                        :rules="[rules.required]"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="5">
                      <v-select
                        v-model.trim="requestLocal.type"
                        :items="requestTypes"
                        item-text="title"
                        item-value="meta.id"
                        required
                        return-object
                        :rules="[rules.required]"
                        outlined
                        label="Abfragesart"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="7">
                      <v-text-field
                        v-model.trim="requestLocal.location.name"
                        label="Veranstaltungsort"
                        prepend-inner-icon="mdi-map-marker"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model.trim="requestLocal.description"
                        counter
                        clearable
                        rows="4"
                        no-resize
                        auto-grow
                        label="Beschreibung"
                        outlined
                        hide-details="auto"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="12">
          <Subheader
            icon="mdi-calendar-range"
            title="Terminoptionen"
          ></Subheader>
          <div class="mt-7">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-form
                      @submit.prevent="createItems"
                      ref="timeSlotsForm"
                      v-model="helpers.timeSlotsData.isValid"
                    >
                      <v-data-table
                        v-model="helpers.timeSlotsData.selected"
                        :headers="headers"
                        :items="requestLocal.timeSlots.data"
                        single-expand
                        item-key="id"
                        expandable
                        no-data-text="Keine Terminoptionen vorhanden"
                        show-expand
                        disable-sort
                        disable-filtering
                        :expanded.sync="helpers.timeSlotsData.expanded"
                      >
                        <template v-slot:top>
                          <v-card flat>
                            <v-card-title>
                              <v-btn
                                depressed
                                text
                                :disabled="
                                  requestLocal.timeSlots.data.length < 2
                                "
                                @click="sortAllDocuments()"
                                ><v-icon left
                                  >mdi-sort-calendar-ascending</v-icon
                                >Sortieren nach Startzeitpunkt</v-btn
                              >
                              <v-btn
                                depressed
                                text
                                :disabled="
                                  requestLocal.timeSlots.data.length === 0
                                "
                                @click="validateAllDocuments()"
                                ><v-icon left>mdi-check-all</v-icon>Datensätze
                                validieren</v-btn
                              >
                              <v-btn
                                depressed
                                text
                                color="error"
                                :disabled="
                                  requestLocal.timeSlots.data.length === 0
                                "
                                @click="removeAllDocuments()"
                                ><v-icon left>mdi-delete</v-icon>Alle
                                entfernen</v-btn
                              >

                              <v-spacer></v-spacer>
                              <v-btn
                                depressed
                                color="success"
                                @click="addTimeSlot()"
                              >
                                <v-icon left>mdi-calendar-plus</v-icon
                                >Hinzufügen
                              </v-btn>
                            </v-card-title>
                          </v-card>
                        </template>

                        <template v-slot:item.title="{ item }"
                          ><v-text-field
                            v-model="item.title"
                            :rules="[rules.required]"
                            outlined
                            dense
                            class="mt-5"
                            style="min-width: 140px"
                          ></v-text-field
                        ></template>
                        <template v-slot:item.priority="{ item }">
                          <v-btn-toggle v-model="item.priority" mandatory>
                            <v-btn
                              v-for="n in 3"
                              :key="n"
                              :value="n"
                              text
                              small
                              icon
                              @click="item.priority = n"
                            >
                              {{ n }}
                            </v-btn>
                          </v-btn-toggle>
                        </template>
                        <template v-slot:item.startDate="{ item, index }"
                          ><v-text-field
                            v-model="item.start.date"
                            :rules="[rules.required]"
                            outlined
                            type="date"
                            dense
                            hint="DD.MM.YYYY"
                            class="mt-5"
                            @blur="startDateChanged(item.start.date, index)"
                          ></v-text-field>
                        </template>
                        <template v-slot:item.startTime="{ item }">
                          <v-text-field
                            v-model="item.start.time"
                            :rules="[rules.required]"
                            outlined
                            type="time"
                            hint="HH:MM"
                            dense
                            class="mt-5"
                          ></v-text-field
                        ></template>

                        <template v-slot:item.endDate="{ item }"
                          ><v-text-field
                            v-model="item.end.date"
                            :rules="[rules.required]"
                            outlined
                            type="date"
                            hint="DD.MM.YYYY"
                            dense
                            class="mt-5"
                          ></v-text-field>
                        </template>
                        <template v-slot:item.endTime="{ item }"
                          ><v-text-field
                            v-model="item.end.time"
                            :rules="[rules.required]"
                            outlined
                            type="time"
                            dense
                            hint="HH:MM"
                            class="mt-5"
                          ></v-text-field
                        ></template>
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                            <v-row>
                              <v-col cols="12" sm="12" md="2">
                                <div class="mt-5 grey--text">
                                  Anforderung für Terminoption
                                </div>
                              </v-col>
                              <v-col cols="12" sm="12" md="2">
                                <v-text-field
                                  v-model.number="
                                    item.requestedResources.minPersons
                                  "
                                  type="number"
                                  :rules="[rules.min]"
                                  outlined
                                  label="Min. Personen"
                                  clearable
                                  dense
                                  class="mt-5"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="2">
                                <v-text-field
                                  v-model.number="
                                    item.requestedResources.maxPersons
                                  "
                                  type="number"
                                  outlined
                                  label="Max. Personen"
                                  :rules="[rules.not0, rules.min]"
                                  clearable
                                  dense
                                  class="mt-5"
                                ></v-text-field> </v-col
                              ><v-col cols="12" sm="12" md="6">
                                <v-select
                                  v-model="item.requestedResources.unitIds"
                                  :items="units"
                                  label="Besetzbare Einsatzmittel"
                                  outlined
                                  multiple
                                  required
                                  item-text="title"
                                  item-value="meta.id"
                                  clearable
                                  disabled
                                  dense
                                  hide-details="auto"
                                  class="mt-5"
                                >
                                  <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index < 2" small>
                                      <span>{{ item.title }}</span>
                                    </v-chip>
                                    <span
                                      v-if="index === 2"
                                      class="grey--text text-caption"
                                    >
                                      (+{{
                                        item.requestedResources.unitIds.length -
                                        2
                                      }}
                                      weitere)
                                    </span>
                                  </template></v-select
                                ></v-col
                              >
                            </v-row>
                          </td>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-btn icon text @click="duplicateDocument(item)">
                            <v-icon>mdi-content-duplicate</v-icon>
                          </v-btn>
                          <!-- <v-menu
                            v-model="helpers.timeSlotsAssistant.model"
                            :close-on-click="false"
                            :close-on-content-click="false"
                            :nudge-width="150"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon text v-bind="attrs" v-on="on">
                                <v-icon>mdi-auto-fix</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-form
                                @submit.prevent="duplicateWithAssistant"
                                ref="timeSlotsAssistant"
                                v-model="helpers.timeSlotsAssistant.isValid"
                              >
                                <v-list>
                                  <v-list-item> </v-list-item>
                                </v-list>

                                <v-card-actions class="mt-n4">
                                  <v-btn
                                    text
                                    @click="
                                      helpers.timeSlotsAssistant.model = false
                                    "
                                  >
                                    Abbrechen
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    text
                                    :disabled="
                                      !helpers.timeSlotsAssistant.isValid
                                    "
                                    @click="duplicateWithAssistant()"
                                  >
                                    Duplizieren mit Assistent
                                  </v-btn>
                                </v-card-actions>
                              </v-form>
                            </v-card>
                          </v-menu> -->
                          <v-btn
                            color="error"
                            icon
                            text
                            @click="removeDocument(item)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-form>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <Subheader icon="mdi-cog" title="Einstellungen"></Subheader>
          <div class="mt-7">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-checkbox
                        v-model="requestLocal.config.attendance.isLimited"
                        label="Teilnahme an Abfrage beschränken"
                        persistent-hint
                        hint="Standardmäßig können alle Mitglieder der Organisation an der Abfrage teilnehmen und eine Rückmeldung abgeben. Die Abgabe von Rückmeldungen und Teilnahme an der Abfeage kann die Teilnahme jedoch auf einzelne Personen/Teams/Aufgabenbereiche beschränkt werden."
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      v-if="requestLocal.config.attendance.isLimited"
                    >
                      <v-text-field
                        v-model.trim="
                          requestLocal.config.attendance.description
                        "
                        :disabled="!requestLocal.config.attendance.isLimited"
                        outlined
                        label="Text oder Beschreibung"
                        hide-details="auto"
                        clearable
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      v-if="requestLocal.config.attendance.isLimited"
                    >
                      <v-autocomplete
                        v-model="
                          requestLocal.config.attendance.allowedUserGroups
                        "
                        :items="userGroups"
                        chips
                        :disabled="!requestLocal.config.attendance.isLimited"
                        small-chips
                        deletable-chips
                        outlined
                        return-object
                        label="Benutzergruppen einladen"
                        :rules="[rules.attendanceLimitation]"
                        item-text="title"
                        item-value="meta.id"
                        multiple
                        clearable
                        hide-details="auto"
                        append-inner-icon="mdi-infinity"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="requestLocal.config.attendance.isLimited"
                    >
                      <v-autocomplete
                        v-model="requestLocal.config.attendance.allowedPersons"
                        :items="membershipsListFiltered"
                        chips
                        :disabled="!requestLocal.config.attendance.isLimited"
                        small-chips
                        :rules="[rules.attendanceLimitation]"
                        outlined
                        deletable-chips
                        return-object
                        label="Personen einladen"
                        item-text="displayName"
                        item-value="uid"
                        multiple
                        clearable
                        hide-details="auto"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-checkbox
                        v-model="requestLocal.config.organizer.isAppointed"
                        label="Organisator(en) festlegen"
                        persistent-hint
                        hint="Organisatoren werden in den Abfragedetails angezeigt und werden bei Abwesenheiten benachrichtigt."
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="5"
                      v-if="requestLocal.config.organizer.isAppointed"
                    >
                      <v-text-field
                        v-model.trim="requestLocal.config.organizer.description"
                        :disabled="!requestLocal.config.organizer.isAppointed"
                        outlined
                        label="Text oder Beschreibung"
                        hide-details="auto"
                        clearable
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="7"
                      v-if="requestLocal.config.organizer.isAppointed"
                    >
                      <v-autocomplete
                        v-model="requestLocal.config.organizer.personData"
                        :items="membershipsListFiltered"
                        :disabled="!requestLocal.config.organizer.isAppointed"
                        chips
                        small-chips
                        outlined
                        return-object
                        deletable-chips
                        label="Personen als Organisatoren hinzufügen"
                        item-text="displayName"
                        item-value="uid"
                        multiple
                        clearable
                        hide-details="auto"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="requestLocal.config.organizer.isAppointed"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-checkbox
                        v-model="
                          requestLocal.config.organizer.hasAdditionalPermissions
                        "
                        label="Erweiterte Berechtigungen für Organisatoren"
                        persistent-hint
                        hint="Organisatoren können diese Abfrage verwalten unabhängig von ihren sonstigen Berechtigungen."
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-checkbox
                        v-model="
                          requestLocal.config.responses.deadline.isCustom
                        "
                        label="Benutzerdefinierte Rückmeldefrist verwenden"
                        persistent-hint
                        hint="Die Rückmeldefrist wird entsprechend Deiner globalen Konfiguration gesetzt, sofern Du diese hier nicht für diese Abfrage anpasst. Bei Aktivieren der benutzdefinierten Anmeldefrist wird die globale Konfiguration nicht mehr auf diese Abfrage angewandt!"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      v-if="requestLocal.config.responses.deadline.isCustom"
                    >
                      <v-text-field
                        v-model="requestLocal.config.responses.deadline.date"
                        type="date"
                        :disabled="
                          !requestLocal.config.responses.deadline.isCustom
                        "
                        :rules="
                          requestLocal.config.responses.deadline.isCustom
                            ? [rules.required]
                            : ''
                        "
                        label="Datum (Ablauf der Frist)"
                        hint="DD.MM.YYYY"
                        outlined
                        prepend-inner-icon="mdi-calendar-end"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-if="requestLocal.config.responses.deadline.isCustom"
                    >
                      <v-text-field
                        v-model="requestLocal.config.responses.deadline.time"
                        label="Zeit (Ablauf der Frist)"
                        hint="HH:MM"
                        :disabled="
                          !requestLocal.config.responses.deadline.isCustom
                        "
                        :rules="
                          requestLocal.config.responses.deadline.isCustom
                            ? [rules.required]
                            : ''
                        "
                        outlined
                        type="time"
                        prepend-inner-icon="mdi-clock-end"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-checkbox
                        v-model="requestLocal.config.responses.areBinding"
                        label="Rückmeldungen sind verbindlich"
                        persistent-hint
                        hint="Abgegebene Rückmeldungen zur Abfrage können nach Abgabe nur noch durch berechtigte Nutzer bearbeitet werden."
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-checkbox
                        v-model="requestLocal.config.responses.areLocked"
                        label="Rückmeldungen sperren"
                        persistent-hint
                        hint="Das Bearbeiten und die Abgabe von neuen Rückmeldungen ist nicht mehr möglich. Vorhandene Rückmeldungen bleiben bestehen. Die Rückmeldefunktion wird eingefroren."
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { db } from "@/firebase.js";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { ADMIN, AVAILABILITY } from "@/store/modules.js";
import {
  GET_REQUEST_TYPES,
  GET_USER_LIST,
  GET_USER_GROUPS,
  GET_UNITS,
} from "@/store/action-types.js";
export default {
  name: "request-editor",
  props: {
    request: {},
  },
  components: {
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Erforderlich",
        startBeforeEnd: () => "Start muss vor Ende liegen",
        min: (value) =>
          /^$|(?!0+(?:\.0+)?)\d*(?:\.\d+)?$/.test(value) ||
          "Muss positive Zahl sein.",
        not0: (value) => value !== 0 || "Muss größer als 0 sein.",
      },
      headers: [
        { text: "Titel", value: "title" },
        {
          text: "Priorität",
          value: "priority",
          divider: true,
          width: "1%",
        },
        { text: "Startdatum", value: "startDate" },
        { text: "Startzeit", value: "startTime", divider: true },
        { text: "Enddatum", value: "endDate" },
        { text: "Endzeit", value: "endTime", divider: true },
        { text: "", value: "data-table-expand" },
        { text: "Aktionen", value: "actions" },
      ],
      helpers: {
        timeSlotsData: {
          isValid: false,
          expanded: [],
          selected: [],
        },
        timeSlotsAssistant: {
          isValid: false,
          model: false,
          template: {
            until: new Date(new Date().setMonth(new Date().getMonth() + 1)),
            recurrence: "weekly", // daily, weekly, monthly, yearly
          },
        },
      },
    };
  },
  model: {
    prop: "request",
    event: "requestChange",
  },
  computed: {
    requestLocal: {
      get: function () {
        return this.request;
      },
      set: function (value) {
        this.$emit("requestChange", value);
      },
    },
    requestTypes() {
      var result = this.$store.state.availability.requestTypes.map((item) => {
        const container = {
          title: item.title,
          shortTitle: item.shortTitle,
          color: item.color,
          description: item.description,
          id: item.meta.id,
        };
        return container;
      });
      return result;
    },
    units() {
      return this.$store.state.availability.units;
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    userGroups() {
      const userGroups = this.$store.state.admin.userGroups.map((obj) => ({
        title: obj.title,
        id: obj.meta.id,
      }));
      return userGroups;
    },
    membershipsListFiltered() {
      const input = this.memberships;
      const groupItems = (arr) => {
        return arr.reduce((a, c) => {
          if (typeof a[c.group.title] === "undefined") a[c.group.title] = [];
          a[c.group.title] = [...a[c.group.title], c];
          return a;
        }, {});
      };
      const step1 = groupItems(input);
      const insertHeaders = (obj) => {
        return Object.entries(obj).reduce((a, [key, vals], i, d) => {
          let r = [
            {
              header: key,
            },
            ...vals,
          ];
          // this is needed to have the exact same array
          // that you presented as desirable output:
          // no divider at the last place
          if (i < d.length - 1) {
            r = [
              ...r,
              {
                divider: true,
              },
            ];
          }
          a = [...a, ...r];
          return a;
        }, []);
      };
      const step2 = insertHeaders(step1);
      const step3 = step2
        .filter(
          // FIXME: temporary fix, to be watched
          (item) =>
            item.header || (item.user && item.user.displayName && item.user.uid)
        )
        .map((item) => {
          if (item.header) {
            // Skip headers and dividers
            return item;
          } else if (item.user && item.user.displayName && item.user.uid) {
            // Extract displayName and uid from membership object
            return {
              displayName: item.user.displayName || "",
              uid: item.user.uid,
            };
          } else {
            return {
              displayName: "",
              uid: "",
            };
          }
        });

      return step3;
    },
    membersFiltered() {
      var input = this.memberships;
      var filtered = input.filter((item) => {
        return item.group.id == this.$route.params.itemId;
      });
      return filtered;
    },
    // personAttendanceIds() {
    //   return this.config.attendance.allowedPersons.map((item) => item.uid);
    // },
    // userGroupAttendanceIds() {
    //   return this.config.attendance.allowedUserGroups.map((item) => item.id);
    // },
    // allAttendanceIds() {
    //   return this.personAttendanceIds.concat(this.userGroupAttendanceIds);
    // },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${AVAILABILITY}${GET_REQUEST_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      this.$store.dispatch(`${ADMIN}${GET_USER_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      this.$store.dispatch(`${AVAILABILITY}${GET_UNITS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    // Validation function that accepts startDate, startTime, endDate, endTime and checks returns an error message if start is before the end
    // startBeforeEnd(startDate, startTime, endDate, endTime) {
    //   if (startDate === null || startTime === null || endDate === null || endTime === null) {
    //     return true;
    //   } else {
    //     const start = new Date(startDate + " " + startTime);
    //     const end = new Date(endDate + " " + endTime);
    //     if (start < end) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // },
    startDateChanged(startDate, index) {
      this.requestLocal.timeSlots.data[index].end.date = startDate;
    },
    generateId() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("requests")
        .doc().id;
    },
    addTimeSlot() {
      if (this.requestLocal.timeSlots.data.length > 65) {
        alert(
          "Es können nicht mehr als 65 Datensätze gleichzeitig hinzugefügt werden."
        );
      } else {
        const newDocument = {
          id: this.generateId(),
          priority: 3,
          title: "",
          eventCreated: false,
          start: {
            date: null,
            time: null,
            timestamp: null,
          },
          end: {
            date: null,
            time: null,
            timestamp: null,
          },
          requestedResources: {
            minPersons: null,
            maxPersons: null,
            unitIds: [],
          },
        };
        this.requestLocal.timeSlots.data.push(newDocument);
      }
    },
    duplicateDocument(item) {
      if (this.requestLocal.timeSlots.data.length > 65) {
        alert(
          "Es können nicht mehr als 65 Datensätze gleichzeitig hinzugefügt werden."
        );
      } else {
        const duplicate = {
          ...item,
          id: this.generateId(),
        };
        this.requestLocal.timeSlots.data.push(duplicate);
      }
    },
    removeDocument(document) {
      this.requestLocal.timeSlots.data.splice(
        this.requestLocal.timeSlots.data.indexOf(document),
        1
      );
    },
    removeAllDocuments() {
      var res = confirm(
        "Bist Du sicher, dass Du alle Datensätze entfernen möchtest?"
      );
      if (res == true) {
        this.requestLocal.timeSlots.data = [];
      }
    },
    duplicateWithAssistant() {},
    resetAssistantSettings() {
      this.helpers.timeSlotsAssistant.template = {
        until: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        recurrence: "weekly", // daily, weekly, monthly, yearly
      };
    },
    validateAllDocuments() {
      let length = this.requestLocal.timeSlots.data.length;
      for (let i = 0; i < length; i++) {
        // this.$refs.requestLocal.timeSlots.data[i].validate();
        this.$refs.timeSlotsForm.validate();
      }
    },
    sortAllDocuments() {
      this.requestLocal.timeSlots.data = this.requestLocal.timeSlots.data.sort(
        function (a, b) {
          console.log(new Date(a.start.date), new Date(b.start.date));
          return new Date(a.start.date) - new Date(b.start.date);
        }
      );
    },
  },
};
</script>
